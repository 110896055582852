import React, { memo, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, ClickAwayListener, Grid, Popover, Typography, useMediaQuery } from '@mui/material';
import CheckboxIcon from '../../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import CheckboxIconChecked from '../../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import SortIcon from '../../../../packages/common/shared-ui/src/icons/SortIcon';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import { getTranslatedText } from '../../../../packages/common/utils/getTranslatedText';
import TableSecondStepPopover from '../TableSecondStepPopover';

import useStyles from './SpentTimeTable.styles';
import clsx from 'clsx';

import { DataGridPro } from '@mui/x-data-grid-pro';
import { Link, useLocation } from 'react-router-dom';
import { generateRedirectUrl } from '../../../../packages/common/utils/getRedirectUrl';
import { store } from '../../app/redux';

const SpentTimeTable = ({
  query,
  setQuery,
  intl,
  originalTimesheetsData,
  formattedTimesheetsData,
  isLoading,
  setIsLoading,
  tableErrorMessage,
  approveOrRejectTimesheets,
  approveOrRejectOvertime,
  handleDeleteTimesheet,
  contextMenuData,
  setContextMenuData,
  currentType,
  tableSelectedValues,
  setTableSelectedValues,
  timesheetsColumnsValue,
}) => {
  const timesheetsData = formattedTimesheetsData();
  const classes = useStyles();
  const navigate = useNavigate();
  const state = store.getState();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.maxWidth('lg'));
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [sortModel, setSortModel] = useState(
    query.sorts ? [{ field: query.sorts.split(';')[0], sort: query.sorts.split(';')[1] }] : [],
  );
  const [, forceUpdate] = useReducer(x => x - 1, 0);
  const { search } = useLocation();

  const handleGetRedirectUrl = useCallback(
    ({ id, columnType }) => {
      const issueLink = state.user.project ? `/issues/${id}?project=${state.user.project.redmineId}` : `/issues/${id}`;
      switch (columnType) {
        case 'user':
          return generateRedirectUrl({ host: window.location.origin, path: `/users/${id}` });
        case 'project':
          return `${window.location.origin}/projects/${id}`;
        case 'issue':
          return issueLink;
        default:
          return '';
      }
    },
    [state.user.project],
  );

  const cellRenderer = useCallback(
    ({ props, column }) => {
      const linkFields = ['project', 'user', 'issue'];
      if (!props.value && props.row.groupName) {
        if (props.colDef.position === 0) {
          return (
            <Typography variant="h4" fontWeight={700}>
              {props.row.groupName}
            </Typography>
          );
        }
      } else {
        if (linkFields.includes(column.fieldType)) {
          const splittedColumnName = props?.value?.split('$$');
          return (
            <Link to={handleGetRedirectUrl({ id: splittedColumnName[1], columnType: splittedColumnName[2] })}>
              <Typography variant="h4" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                {splittedColumnName[0]}
              </Typography>
            </Link>
          );
        }
      }
    },
    [handleGetRedirectUrl],
  );

  const formattedTimesheetsColumns = useCallback(() => {
    const smallFields = ['date', 'hours', 'float', 'bool'];
    return timesheetsColumnsValue.map(column => {
      return {
        field: column.fieldId,
        headerName: getTranslatedText(intl, 'filter', column.fieldId.split('-').join('_'), column.fieldName),
        renderHeader: props => (
          <Typography variant="h4" fontWeight={700}>
            {props.colDef.headerName}
          </Typography>
        ),
        disableExport: false,
        position: column.position,
        //sortable: query.groups !== column.fieldId,
        flex: smallFields.includes(column.fieldType) ? 0 : 1,
        width: smallFields.includes(column.fieldType) && 120,
        renderCell: props => cellRenderer({ props, column }),
        cellClassName: props => !props.row.groupName && 'tableCell',
      };
    });
  }, [cellRenderer, intl, timesheetsColumnsValue]);

  const timesheetsColumns = formattedTimesheetsColumns();

  useEffect(() => {
    if (sortModel.length === 0 && query.sorts?.length > 0) {
      setQuery({ sorts: undefined });
      return setIsLoading(true);
    }
    if (sortModel.length > 0 && query.sorts !== Object.values(sortModel[0]).join(';')) {
      setQuery({ sorts: Object.values(sortModel[0]).join(';') });
      setIsLoading(true);
    }
  }, [isLoading, query.sorts, setIsLoading, setQuery, sortModel]);

  const contextPopperStylePosition = useRef({
    left: 0,
    top: 0,
    maxWidth: 180,
  });

  const handleEditTimesheet = useCallback(() => {
    let url = '';
    if (contextMenuData.currentRow) {
      const state = {
        rowId: contextMenuData.currentRow.rowId,
        projectId: contextMenuData.currentRow.services.projectId,
        issueId: contextMenuData.currentRow.services.issueId,
        hours: contextMenuData.currentRow.services.hours,
        spentOn: contextMenuData.currentRow.services.spentOn,
        userId: contextMenuData.currentRow.services.userId,
        isOvertime: contextMenuData.currentRow.services.isOvertime,
      };
      url = `/spentTime/create${search}&userId=${state.userId}&projectId=${state.projectId}&issueId=${state.issueId}&rowId=${state.rowId}&spentOn=${state.spentOn}&hours=${state.hours}&isOverTime=${state.isOvertime}`;
    }

    if (contextMenuData.currentRows?.length > 1) {
      const timesheetsIds = contextMenuData.currentRows.join(',');
      url = `/spentTime/create/bulk${search}&timesheets=${timesheetsIds}`;
    }
    window.open(url, '_blank');
  }, [contextMenuData.currentRow, contextMenuData.currentRows, search]);

  const handleChooseSubItemType = useCallback(
    (type, value, itemPosition) => {
      const itemsCount = 4;
      setContextMenuData(prevState => {
        return {
          ...prevState,
          isSecondStepVisible: !prevState.isSecondStepVisible,
          secondPopoverStepValue: value,
          secondStepType: type,
          positionInfo: {
            itemPosition: itemPosition,
            positionDelta: itemsCount - itemPosition - 2,
          },
        };
      });
    },
    [setContextMenuData],
  );

  const handleChooseSubItem = useCallback(
    async value => {
      setContextMenuData(prevState => ({ ...prevState, isSecondStepVisible: false }));
      setIsContextMenuOpen(false);
      if (contextMenuData.secondStepType === 'approve') {
        await approveOrRejectTimesheets(
          tableSelectedValues?.map(selectedValueId => {
            return {
              redmineId: Number(selectedValueId),
              isApproved: value === 'approve',
            };
          }),
        );
      } else if (contextMenuData.secondStepType === 'overtime') {
        try {
          const valuesForUpdate = originalTimesheetsData.filter(row => {
            return tableSelectedValues?.find(value => {
              return row.rowId === value;
            });
          });
          const formattedDataForUpdate = valuesForUpdate.map(selectedValue => {
            return {
              redmineId: selectedValue.rowId,
              projectId: selectedValue.services.projectId,
              issueId: selectedValue.services.issueId,
              hours: selectedValue.services.hours,
              comments: selectedValue.services.comments,
              spentOn: selectedValue.services.spentOn,
              userId: selectedValue.services.userId,
              isOvertime: value === 'approve',
            };
          });
          await approveOrRejectOvertime(formattedDataForUpdate);
        } catch (error) {
          console.error('ERROR ON CHOOSE', error);
        }
      }
    },
    [
      approveOrRejectOvertime,
      approveOrRejectTimesheets,
      contextMenuData.secondStepType,
      originalTimesheetsData,
      setContextMenuData,
      tableSelectedValues,
    ],
  );

  const handleUnselect = useCallback(() => {
    setIsContextMenuOpen(false);
    setContextMenuData(prevState => ({
      ...prevState,
      isSecondStepVisible: false,
      currentRow: {},
    }));
    setTableSelectedValues([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setContextMenuData]);

  const isDisabledMenuItems = {
    isProjectClosed: true,
    isVisibleApprove:
      !contextMenuData.currentRow.services?.canApprove || contextMenuData.currentRow.services?.isProjectClosed,
    isVisibleCanEditOrOvertimeItem:
      contextMenuData.currentRow.services?.isApproved ||
      !contextMenuData.currentRow.services?.canEdit ||
      contextMenuData.currentRow.services?.isProjectClosed,
  };

  const isSelectedRowsIncludesClosedProjects = originalTimesheetsData.filter((row, index, array) => {
    const selectedValue = tableSelectedValues?.find(value => {
      return row.rowId === value;
    });
    if (selectedValue && row.services.isProjectClosed) {
      return array[index];
    }
  });

  const isSelectedRowsIncludesNotApproved = originalTimesheetsData.filter((row, index, array) => {
    const selectedValue = tableSelectedValues?.find(value => {
      return row.rowId === value;
    });
    if (selectedValue && !row.services.canApprove) {
      return array[index];
    }
  });
  const isSelectedRowsIncludesApproved = originalTimesheetsData.filter((row, index, array) => {
    const selectedValue = tableSelectedValues?.find(value => {
      return row.rowId === value;
    });
    if (selectedValue && row.services.isApproved) {
      return array[index];
    }
  });

  const selectRow = useCallback(
    ({ rowId, clear = false }) => {
      setTableSelectedValues(prevState => {
        if (clear) {
          return [rowId];
        }
        if (prevState.includes(rowId)) {
          return prevState.filter(id => id !== rowId);
        }
        return [...prevState, rowId];
      });
      forceUpdate();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setTableSelectedValues],
  );

  const handleRowContextMenu = e => {
    e.preventDefault();
    if (!e.currentTarget) {
      return;
    }
    const groupRow = e.currentTarget.className.includes('groupRow');
    const rowId = e.currentTarget.getAttribute('data-id');
    if (!isContextMenuOpen && !groupRow) {
      const currentRow = originalTimesheetsData.find(row => row.rowId === rowId);
      setContextMenuData(prevState => {
        prevState.currentRow = currentRow;
        prevState.currentRows = tableSelectedValues;
        prevState.isSecondStepVisible = false;
        prevState.secondStepType = '';
        return prevState;
      });
      contextPopperStylePosition.current.left = e.clientX;
      contextPopperStylePosition.current.top = e.clientY;
      if (!tableSelectedValues.includes(rowId)) {
        selectRow({ rowId: rowId });
      }
      setIsContextMenuOpen(true);
      //forceUpdate();
    }
  };

  return (
    <>
      {isSmallScreen ? (
        <></>
      ) : (
        <Box>
          {isContextMenuOpen && (
            <ClickAwayListener onClickAway={handleUnselect}>
              <Popover
                keepMounted
                open={isContextMenuOpen}
                style={contextPopperStylePosition.current}
                anchorPosition={contextPopperStylePosition.current}
                className={classes.contextPopover}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                anchorReference="none"
              >
                <TableSecondStepPopover
                  value={contextMenuData.secondPopoverStepValue}
                  handleChooseSubItem={handleChooseSubItem}
                  isSecondStepVisible={contextMenuData.isSecondStepVisible}
                  contextPopperStylePosition={contextPopperStylePosition}
                  positionInfo={contextMenuData.positionInfo}
                />
                <Grid container direction="column">
                  <Grid
                    item
                    xs={12}
                    padding="6px 20px"
                    className={clsx(classes.contextMenuItem, {
                      [classes.contextMenuItemDisabled]:
                        !contextMenuData.currentRow.services.canEdit ||
                        isSelectedRowsIncludesApproved.length > 0 ||
                        isSelectedRowsIncludesClosedProjects.length > 0,
                    })}
                    onClick={handleEditTimesheet}
                  >
                    {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                    <Typography variant="h5">Редактировать</Typography>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    padding="6px 0 6px 20px"
                    className={clsx(classes.contextMenuItem, {
                      [classes.contextMenuItemDisabled]:
                        isSelectedRowsIncludesNotApproved.length > 0 || isSelectedRowsIncludesClosedProjects.length > 0,
                    })}
                    wrap="nowrap"
                    alignItems="center"
                    onClick={() =>
                      handleChooseSubItemType('approve', contextMenuData.currentRow.services.isApproved, 1)
                    }
                  >
                    {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                    <Typography variant="h5">Утверждено</Typography>
                    <Box width={24} height={24}>
                      <ChevronIcon direction="right" color="rgba(228, 228, 239, 1)" viewBox="0 0 24 24" />
                    </Box>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    padding="6px 0 6px 20px"
                    className={clsx(classes.contextMenuItem, {
                      [classes.contextMenuItemDisabled]:
                        isSelectedRowsIncludesApproved.length > 0 || isSelectedRowsIncludesClosedProjects.length > 0,
                    })}
                    wrap="nowrap"
                    alignItems="center"
                    onClick={() =>
                      handleChooseSubItemType('overtime', contextMenuData.currentRow.services.isOvertime, 2)
                    }
                  >
                    {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                    <Typography variant="h5">Переработка</Typography>
                    <Box width={24} height={24}>
                      <ChevronIcon direction="right" color="rgba(228, 228, 239, 1)" viewBox="0 0 24 24" />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    padding="6px 20px"
                    className={clsx(classes.contextMenuItem, {
                      [classes.contextMenuItemDisabled]:
                        isSelectedRowsIncludesApproved.length > 0 || isSelectedRowsIncludesClosedProjects.length > 0,
                    })}
                    onClick={() => {
                      setIsContextMenuOpen(false);
                      setContextMenuData(prevState => ({ ...prevState, isSecondStepVisible: false }));
                      handleDeleteTimesheet({
                        projectId: contextMenuData.currentRow.services.projectId,
                        timesheetId: contextMenuData.currentRow.rowId,
                        tableSelectedValues: tableSelectedValues,
                      });
                    }}
                  >
                    {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                    <Typography variant="h5">Удалить</Typography>
                  </Grid>
                </Grid>
              </Popover>
            </ClickAwayListener>
          )}
          <Box marginTop={5}>
            <Grid container justifyContent={tableErrorMessage ? 'space-between' : 'flex-end'}>
              {typeof tableErrorMessage === 'string' && (
                <Typography variant="h5" fontWeight={600} color="#E03737">
                  {tableErrorMessage}
                </Typography>
              )}
              <Box display="flex" wrap="nowrap">
                {originalTimesheetsData[0].columns.map(column => {
                  return (
                    <Box key={column.fieldId} display="flex" flexWrap="nowrap" alignItems="center" marginLeft={2}>
                      <Typography variant="h5" marginRight={2}>
                        {getTranslatedText(intl, 'filter', column.fieldId, column.fieldName)}:
                      </Typography>
                      <Typography variant="h5" fontWeight={700}>
                        {column.value ? column.value : 0}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Grid>
            <DataGridPro
              autoHeight
              hideFooter
              {...{ ['checkboxSelection']: currentType !== 1 }}
              disableColumnFilter
              disableRowSelectionOnClick
              disableColumnMenu
              columns={timesheetsColumns}
              rows={timesheetsData}
              className={classes.SpentTimeTableRoot}
              rowSelectionModel={tableSelectedValues}
              onRowSelectionModelChange={newRowSelectionModel => {
                setTableSelectedValues(newRowSelectionModel);
              }}
              getRowId={item => item.id}
              sortModel={sortModel ? sortModel : []}
              onSortModelChange={model => setSortModel(model)}
              sortingMode="server"
              sortingOrder={['asc', 'desc', null]}
              slotProps={{
                row: {
                  onContextMenu: e => handleRowContextMenu(e),
                  style: { cursor: 'context-menu' },
                },
                baseCheckbox: {
                  icon: <CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />,
                  checkedIcon: <CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />,
                },
              }}
              slots={{
                columnUnsortedIcon: () => <SortIcon width="20" height="20" viewBox="0 0 20 20" />,
                columnSortedAscendingIcon: () => <SortIcon direction="up" width="20" height="20" viewBox="0 0 20 20" />,
                columnSortedDescendingIcon: () => (
                  <SortIcon direction="down" width="20" height="20" viewBox="0 0 20 20" />
                ),
              }}
              getRowClassName={params => params.row.groupName && 'groupRow'}
              isRowSelectable={params => !params.row.groupName}
              rowHeight={40}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default memo(SpentTimeTable);
