import { makeStyles } from '@mui/styles';

const issuePageStyles = makeStyles(theme => ({
  editorField: {
    //color: '#6270d0',
    wordBreak: 'break-word',
    overflow: 'hidden',
    color: '#212346',
    fontSize: '14px',
    lineHeight: '20px',
    '& a': {
      color: '#6270d0',
      '&:hover': {
        textDecoration: 'underline',
        textDecorationColor: '#212346',
      },
    },
  },
  addButton: {
    '& .MuiSvgIcon-root': {
      '& path': {
        transition: '.2s ease',
      },
    },
    '&:hover': {
      '& .MuiSvgIcon-root': {
        '& path': {
          fill: '#7070B8',
        },
      },
    },
  },
  hoveredIcon: {
    '& .MuiSvgIcon-root': {
      '& path': {
        transition: '.2s ease',
      },
    },
    '&:hover': {
      '& .MuiSvgIcon-root': {
        '& path': {
          fill: '#7174AC',
        },
      },
    },
  },
  fieldsHolder: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  rowLink: {
    transition: '.4s ease',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  userTabs: {
    '&.MuiTabs-root': {
      margin: '20px 0 10px',
      minHeight: 'auto',
    },
    '& .MuiButtonBase-root.MuiTab-root': {
      color: '#656985',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '400',
      padding: '8px 16px',
      textTransform: 'capitalize',
      borderRadius: '16px 0 0 16px',
      backgroundColor: '#DCDFF4',
      minHeight: 'auto',
      minWidth: '79px',
      '& + .MuiButtonBase-root.MuiTab-root': {
        borderRadius: '0 16px 16px 0',
        marginLeft: '2px',
      },
      '&.Mui-selected': {
        backgroundColor: '#6270D0',
        color: '#fff',
      },
    },
  },
  SpentTimeTableRoot: {
    '&.MuiDataGrid-root': {
      borderRadius: '16px 16px 0 0',
      borderColor: '#E4E4EF',
      backgroundColor: '#ffffff',
      '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor': {
        '& + .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor': {
          borderLeft: '1px solid #E4E4EF',
          '&.actions': {
            borderLeft: 'none',
          },
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
          whiteSpace: 'initial',
        },
      },
      '& .MuiDataGrid-row.Mui-selected': {
        backgroundColor: '#DCDFF4',
      },
      '& .MuiDataGrid-row.Mui-selected.Mui-hovered': {
        backgroundColor: '#DCDFF4',
      },
      '& .MuiDataGrid-main .MuiDataGrid-iconButtonContainer .MuiButtonBase-root': {
        marginLeft: theme.spacing(2),
      },
      '& .MuiDataGrid-columnSeparator': {
        '& .MuiSvgIcon-root': {
          width: '24px',
        },
      },
      '& .MuiDataGrid-cell': {
        '&.tableCell': {
          lineHeight: '20px',
          '& .MuiCheckbox-root': {
            padding: 0,
          },
          '&.textCenter': {
            justifyContent: 'center',
          },
          '&.select.MuiDataGrid-cell--editing': {
            padding: 0,
          },
          '& + .tableCell': {
            borderLeft: '1px solid #E4E4EF',
          },
          '&.MuiDataGrid-cell--editing': {
            padding: '0 8px',
          },
        },
        '&.actions': {
          padding: 0,
          '& .MuiDataGrid-actionsCell': {
            height: '100%',
            width: '100%',
            gridGap: 0,
          },
          '& .MuiButtonBase-root.MuiIconButton-root': {
            height: '100%',
            borderRadius: '0',
            padding: '0',
            flex: 1,
            '& .MuiSvgIcon-root': {
              width: '16px',
              height: '16px',
            },
            '& + .MuiButtonBase-root.MuiIconButton-root': {
              borderLeft: '1px solid #E4E4EF',
            },
          },
        },
        '& a': {
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
  },
  paperDropdown: {
    '&.MuiPaper-root.MuiPopover-paper.MuiMenu-paper': {
      maxHeight: '300px',
      borderRadius: '0 0 16px 16px',
      borderTop: '1px solid #E4E4EF',
      boxShadow: '0px 5px 10px -8px #A0A1AA66',
      '&::-webkit-scrollbar': {
        width: '2px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#7174AC',
        borderRadius: '5px',
      },
      '& .MuiList-root.MuiMenu-list': {
        padding: '0',
        '& li': {
          fontSize: '14px',
          lineHeight: '20px',
          color: '#212346',
          '&.MuiButtonBase-root.MuiMenuItem-root': {
            '&:hover': {
              backgroundColor: '#DCDFF4',
            },
          },
        },
      },
    },
  },
  rowSelect: {
    '&.MuiInputBase-root.MuiInput-root': {
      marginTop: 0,
      width: '100%',
      fontSize: '14px',
      lineHeight: '20px',
      height: '100%',
      padding: '8px',
      '&::before': {
        borderBottom: 'none',
      },
      '&:hover': {
        '&::before': {
          borderBottom: 'none',
        },
      },
      '& .MuiSelect-select': {
        padding: 0,
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      '& .MuiSvgIcon-root.MuiSelect-icon': {
        width: '20px',
        height: '20px',
        position: 'static',
      },
      '& .MuiSelect-nativeInput': {
        padding: '0',
      },
      '&.Mui-error': {
        border: '1px solid #E03737',
        '&::after': {
          display: 'none',
        },
      },
    },
  },
  selectLabel: {
    '&.MuiFormLabel-root.MuiInputLabel-root': {
      color: '#7174AC',
      fontSize: '14px',
      lineHeight: '20px',
      marginTop: '-6px',
      transform: 'none',
      left: '8px',
      top: '15px',
      '&.Mui-focused': {
        transform: 'none',
        left: '10px',
        top: '15px',
      },
      '&.MuiFormLabel-filled': {
        display: 'none',
      },
    },
  },
  datePicker: {
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      height: '100%',
      '& .MuiInputBase-input.MuiOutlinedInput-input': {
        padding: 0,
        color: '#7174AC',
        fontSize: '14px',
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  rowInput: {
    '& .MuiInputBase-root.MuiInput-root': {
      fontSize: '14px',
      lineHeight: '20px',
      '&::before': {
        borderBottom: 'none',
      },
      '&:hover': {
        '&::before': {
          borderBottom: 'none',
        },
      },
      '& .MuiInputBase-input.MuiInput-input': {
        color: '#7174AC',
        padding: 0,
        '&::placeholder': {
          opacity: 1,
        },
      },
      '&.Mui-error': {
        border: '1px solid #E03737',
        '&::after': {
          display: 'none',
        },
      },
    },
  },
  issueLink: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  pageText: {
    '& p': {
      margin: 0,
    },
  },
}));

export default issuePageStyles;
