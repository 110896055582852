import React, { useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { injectIntl } from 'react-intl';
import PlusIcon from '../../../../../packages/common/shared-ui/src/icons/PlusIcon';
import { getGantPredecessorIssues } from '../../../../../packages/common/api';
import { Link, useParams } from 'react-router-dom';
import ChevronIcon from '../../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import SubmitIcon from '../../../../../packages/common/shared-ui/src/icons/SubmitIcon';
import DeleteIconBig from '../../../../../packages/common/shared-ui/src/icons/DeleteIconBig';
import issueRelatedStyles from '../../../issuePage/components/IssueRelated/IssueRelated.styles';
import issuePageStyles from '../../../issuePage/IssuePage.styles';
import { relationsSelectValues } from '../../../issuePage/components/IssueRelated/relationsSelectValues';
import UserNewEditIcon from '../../../../../packages/common/shared-ui/src/icons/UserNewEditIcon';
import clsx from 'clsx';

const GanttIssuePredecessor = ({ intl, issueId, projectId, socket, token, defaultPredecessors }) => {
  const params = useParams();
  const classes = issueRelatedStyles();
  const issueCommonStyles = issuePageStyles();
  const [predecessors, setPredecessors] = useState([]);
  const [predecessorFieldOptions, setPredecessorFieldOptions] = useState([]);
  const [predecessorLoading, setPredecessorLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (defaultPredecessors.length > 0) {
      setPredecessorLoading(false);
      if (predecessors.length > 0) {
        setPredecessors(
          predecessors
            .filter(item => !item.deleted)
            .map(item => {
              const saved = defaultPredecessors.find(el => item.predecessorId === el.id);
              if (saved !== undefined) {
                return { predecessorId: saved.id, predecessorName: saved.name, delay: saved.delay };
              } else {
                return item;
              }
            }),
        );
      } else {
        setPredecessors(
          defaultPredecessors.map(item => {
            return { predecessorId: item.id, predecessorName: item.name, delay: item.delay };
          }),
        );
      }
    } else {
      setPredecessorLoading(false);
      setPredecessors(predecessors.filter(item => !item.deleted));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPredecessors]);

  const handlePredecessorFieldChange = useCallback(
    async value => {
      let params = {
        search: value,
      };
      issueId && (params.row = issueId);
      projectId && (params.project = projectId);
      try {
        const response = await getGantPredecessorIssues(params);
        if (response) {
          setPredecessorFieldOptions(response.data);
        }
      } catch (error) {
        console.error('ERROR WITH EDIT', error);
      }
    },
    [issueId, projectId],
  );

  const handlePredecessorSelect = useCallback(
    (value, index) => {
      const updatedObject = {
        ...predecessors[index],
        predecessorId: Number(value.valueId),
        predecessorName: value.valueName,
      };
      setPredecessors([...predecessors.slice(0, index), updatedObject, ...predecessors.slice(index + 1)]);
    },
    [predecessors],
  );
  const handleTextField = useCallback(
    (event, type, index) => {
      const updatedObject = { ...predecessors[index], [type]: event.target.value };
      setPredecessors([...predecessors.slice(0, index), updatedObject, ...predecessors.slice(index + 1)]);
    },
    [predecessors],
  );
  const handleNumberField = useCallback(
    (event, type, field) => {
      const reg = new RegExp(/^[0-9-]*$/);
      if (reg.test(event.target.value)) {
        handleTextField(event, type, field);
      }
    },
    [handleTextField],
  );

  const handleSavePredecessor = useCallback(
    async item => {
      if (socket) {
        setPredecessorLoading(true);
        if (!item.predecessorId) {
          return;
        }
        let data = {
          predecessorId: item.predecessorId,
        };
        item.delay && (data.delay = Number(item.delay));
        if (item.new) {
          socket.emit('addGanttModalPredecessor', {
            token: token,
            groupId: projectId,
            rowId: issueId,
            projectId: Number(projectId),
            updatedValue: data,
          });
        } else {
          socket.emit('updateModalPredecessorDelay', {
            token: token,
            groupId: projectId,
            rowId: issueId,
            projectId: Number(projectId),
            updatedValue: data,
          });
        }
      }
    },
    [issueId, projectId, socket, token],
  );

  const handleEditPredecessor = useCallback(
    index => {
      const updatedObject = { ...predecessors[index], edit: true };
      setPredecessors([...predecessors.slice(0, index), updatedObject, ...predecessors.slice(index + 1)]);
    },
    [predecessors],
  );

  const handleDeletePredecessor = useCallback(
    async index => {
      if (predecessors[index].new) {
        const newArray = predecessors.filter((value, i) => i !== index);
        setPredecessors(newArray);
        return;
      }
      const predecessor = { ...predecessors[index], deleted: true };
      setPredecessors([...predecessors.slice(0, index), predecessor, ...predecessors.slice(index + 1)]);
      if (socket) {
        setPredecessorLoading(true);
        socket.emit('deleteGanttModalPredecessor', {
          token: token,
          groupId: projectId,
          rowId: issueId,
          projectId: Number(projectId),
          updatedValue: {
            predecessorId: predecessors[index].predecessorId,
          },
        });
      }
    },
    [issueId, predecessors, projectId, socket, token],
  );
  const handleAddPredecessor = useCallback(() => {
    setPredecessors([{ new: true, predecessorId: '', predecessorName: '', delay: '' }, ...predecessors]);
  }, [predecessors]);

  return (
    <>
      <Grid container alignItems={'center'}>
        <Grid item>
          <Typography variant="h3" color={'#082253'} fontWeight={600}>
            {intl.formatMessage({ id: 'issue_page_predecessor' })}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={handleAddPredecessor} sx={{ padding: '6px 4px' }}>
            <PlusIcon width="24" height="24" viewBox="0 0 24 24" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container direction={'column'} sx={{ position: 'relative' }}>
        {predecessorLoading && (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
            zIndex={99}
            position={'absolute'}
          >
            <CircularProgress color="secondary" />
          </Grid>
        )}
        {error && (
          <Box sx={{ position: 'absolute', top: '-10px', left: 0 }}>
            <Typography variant="h3" color={'#E03737'}>
              {intl.formatMessage({ id: 'issue_related_error' })}
            </Typography>
          </Box>
        )}

        {predecessors.length > 0 &&
          predecessors.map((item, index) => {
            const disabledTextField = item.new ? false : item.edit ? false : true;
            return (
              <Grid key={index} container columnSpacing={4} className={classes.relatedRow} marginTop={'10px'}>
                <Grid item>
                  <Typography variant="h5" color={'#41424E'} marginRight={'5px'}>
                    {`${intl.formatMessage({ id: 'issue_page_related_task_label' })} #`}
                  </Typography>
                  <Autocomplete
                    noOptionsText={intl.formatMessage({ id: 'not_found' })}
                    loadingText={intl.formatMessage({ id: 'search_options' })}
                    filterOptions={x => x}
                    onChange={(event, newValue) => handlePredecessorSelect(newValue, index)}
                    className={classes.filterSelect}
                    disablePortal
                    options={predecessorFieldOptions}
                    disableClearable
                    disabled={!item.hasOwnProperty('new')}
                    sx={{ width: '156px' }}
                    onBlur={() => setPredecessorFieldOptions([])}
                    renderOption={(props, option) => {
                      return (
                        <MenuItem
                          {...props}
                          key={option.valueId + option.valueName}
                          value={option.valueId}
                          selected={option.valueId === item.valueId}
                        >
                          {option.valueName}
                        </MenuItem>
                      );
                    }}
                    getOptionLabel={option => option.valueName || ''}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    PopperComponent={props => <Popper {...props} placement="bottom-start"></Popper>}
                    renderInput={params => (
                      <TextField
                        {...params}
                        onChange={ev => handlePredecessorFieldChange(ev.target.value)}
                        placeholder={intl.formatMessage({ id: 'choose' })}
                      />
                    )}
                    popupIcon={<ChevronIcon direction="down"></ChevronIcon>}
                    value={
                      item.predecessorId
                        ? {
                            valueId: item.predecessorId,
                            valueName: item.predecessorName,
                          }
                        : ''
                    }
                  />
                </Grid>
                <Grid item>
                  <Typography variant="h5" color={'#41424E'} marginRight={'5px'}>
                    {`${intl.formatMessage({ id: 'issue_page_related_task_delay' })}:`}
                  </Typography>
                  <TextField
                    disabled={disabledTextField}
                    sx={{ width: '80px!important' }}
                    onChange={event => handleNumberField(event, 'delay', index)}
                    placeholder={intl.formatMessage({ id: 'enter' })}
                    fullWidth
                    variant="outlined"
                    required
                    value={item.delay ? item.delay : ''}
                    InputLabelProps={{ shrink: false }}
                    className={clsx('formTextField labelLeft', classes.customText)}
                  />
                  <Typography variant="h5" color={'#41424E'} marginLeft={'5px'}>
                    {intl.formatMessage({ id: 'issue_page_related_task_days' })}
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container>
                    {item.edit || item.new ? (
                      <Grid item>
                        <IconButton
                          disabled={!item.predecessorId}
                          onClick={() => handleSavePredecessor(item)}
                          sx={{ padding: '8px', height: '100%' }}
                        >
                          <SubmitIcon />
                        </IconButton>
                      </Grid>
                    ) : (
                      <Grid item>
                        <IconButton
                          onClick={() => handleEditPredecessor(index)}
                          sx={{ padding: '8px', height: '100%' }}
                        >
                          <UserNewEditIcon width="16" height="16" viewBox="0 0 16 16" />
                        </IconButton>
                      </Grid>
                    )}
                    <Grid item>
                      <IconButton
                        onClick={() => handleDeletePredecessor(index)}
                        sx={{ padding: '8px', height: '100%' }}
                        className={issueCommonStyles.hoveredIcon}
                      >
                        <DeleteIconBig />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

export default injectIntl(GanttIssuePredecessor);
