import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  filterSecondInput: {
    '&.MuiFormControl-root .Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '&.MuiFormControl-root .MuiInputBase-root': {
      maxHeight: 30,
    },
  },
  filterInputWrp: {
    width: '100%',
    backgroundColor: '#fff',
  },
  filterInputBox: {
    padding: '10px 16px',
    backgroundColor: '#fff',
    borderRadius: '16px',
    height: '40px',
    '&.MuiButtonBase-root, &.MuiButtonBase-root:hover': {
      backgroundColor: '#fff !important',
      fontSize: 14,
      fontWeight: 400,
      textAlign: 'left',
      padding: '0 16px',
      justifyContent: 'flex-start',
      height: '40px',
    },
  },
  boolFilterWrap: {
    width: '100% !important',
  },
  filterInput: {
    position: 'relative',
    width: '100%',
    '&.MuiFormControl-root .MuiButtonBase-root': {
      maxWidth: 'unset',
      zIndex: 1991,
    },
    '&.MuiFormControl-root .MuiOutlinedInput-notchedOutline': {
      border: '0 !important',
    },
    '&.MuiFormControl-root .MuiInputBase-root': {
      padding: theme.spacing(1, 0, 0.5, 0),
      // flexWrap: 'nowrap',
      position: 'relative',
      height: 'unset',
      minHeight: 40,
      overflowY: 'auto',
      backgroundColor: '#fff',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '&:hover': {
        backgroundColor: '#fff',
      },
      '&:focus': {
        backgroundColor: '#fff',
        border: 0,
      },
      '& .MuiAutocomplete-input': {
        '-webkit-text-fill-color': 'unset',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        padding: theme.spacing(0, 0, 0, 4),
        zIndex: 1990,
        minWidth: 50,
        height: '100%',
        '&::placeholder': {
          ...theme.typography.h5,
          color: '#7174AC',
          opacity: 1,
        },
      },
    },
  },
  filterDefaultActive: {
    '&.MuiFormControl-root .MuiInputBase-root, &.MuiFormControl-root .MuiInputBase-root:hover': {
      borderRadius: theme.spacing(4),
      outline: '1px solid rgba(52, 72, 255, 1)',
    },
    '&.MuiButtonBase-root, &.MuiButtonBase-root:hover': {
      borderRadius: theme.spacing(4),
      outline: '1px solid rgba(52, 72, 255, 1)',
    },
  },
  filterDefaultFirstStepInput: {
    '&.MuiFormControl-root .MuiInputBase-root': {
      '& .MuiAutocomplete-input, & input': {
        width: '100%',
        '-webkit-text-fill-color': 'unset',
        '&::placeholder': {
          ...theme.typography.h4,
          color: 'rgba(33, 35, 70, 1)',
          opacity: 1,
        },
      },
    },
  },
  subItemChip: {
    '&.MuiChip-root': {
      display: 'none',
    },
  },
  filterChip: {
    '&.MuiChip-root': {
      border: '1px solid #E4E4EF',
      height: '30px',
      backgroundColor: '#ffffff',
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      zIndex: 1991,
    },
  },
  autoCompletePopper: {
    // marginTop: `${theme.spacing(1)} !important`,
    zIndex: 2000,
    minWidth: 280,
    maxWidth: 592,
    backgroundColor: '#fff',
    borderRadius: theme.spacing(2.5),
    width: 'fit-content',
    // overflowX: 'hidden',
    // overflowY: 'auto',
    border: '1px solid #E4E4EF',
    '& .MuiAutocomplete-listbox': {
      padding: 0,
      '& .MuiAutocomplete-option': {
        maxHeight: 28,
      },
      '& .MuiAutocomplete-option[aria-selected="true"], & .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
        backgroundColor: '#DCDFF4',
      },
      '& .MuiAutocomplete-option[aria-selected="true"]:hover': {
        backgroundColor: 'rgba(220, 223, 244, .6)',
      },
    },
  },
  StatusFilterItem: {
    backgroundColor: '#ffffff !important',
    border: '0px !important',
    height: '40px',
    borderRadius: '16px !important',
    maxWidth: '100%',
    '& .Mui-selected': {
      backgroundColor: '#dcdff4 !important',
      border: '1px solid #E4E4EF',
      borderRadius: '16px !important',
    },
    '& .MuiToggleButtonGroup-grouped': {
      background: '#ffffff',
      color: '#7174AC',
      borderRadius: '16px',
      border: '0px !important',
      padding: '10px 6px',
      fontSize: '12px',
    },
  },
  defaultFirstStepPaper: {
    position: 'absolute',
    top: 40,
    left: 0,
    width: 'auto',
    minWidth: 300,
    maxWidth: 300,
  },
  dateFirstStepPaper: {
    maxWidth: 'none',
  },
  secondStepInput: {
    '&.MuiInputBase-root .MuiNativeSelect-select': {
      zIndex: 1990,
    },
    maxHeight: 24,
    width: '100%',
  },
  typeArrow: {
    background: '#fff',
  },
  listItemIcon: {
    '&.MuiListItemIcon-root': {
      minWidth: 'unset',
    },
    '& .MuiCheckbox-root': {
      padding: theme.spacing(0, 3),
    },
  },
  listItemButton: {
    '&.MuiButtonBase-root': {
      padding: theme.spacing(2, 1.5),
    },
  },
  filterAutoComplete: {
    '&.MuiAutocomplete-root': {
      '& .MuiAutocomplete-listbox': {
        padding: 0,
      },
    },
  },
  autoCompletePaper: {
    '&.MuiAutocomplete-root': {
      backgroundColor: '#fff',
      borderRadius: theme.spacing(4),
      marginTop: theme.spacing(1),
      '& .MuiAutocomplete-listbox': {
        padding: 0,
        '& .MuiAutocomplete-option': {
          maxHeight: 28,
        },
        '& .MuiAutocomplete-option[aria-selected="true"], & .MuiAutocomplete-option[aria-selected="true"].Mui-focused':
          {
            backgroundColor: '#DCDFF4',
          },
        '& .MuiAutocomplete-option[aria-selected="true"]:hover': {
          backgroundColor: 'rgba(220, 223, 244, .6)',
        },
      },
    },
  },
  filterTooltip: {
    '&.MuiTooltip-popper': {
      zIndex: 2000,
    },
    '& .MuiTooltip-tooltip': {
      marginTop: '0 !important',
      backgroundColor: '#F1F2F8',
      borderRadius: theme.spacing(4),
      padding: theme.spacing(2),
      color: '#212346',
    },
  },
  calendarRoot: {
    '&.MuiCalendarPicker-root': {
      width: '100%',
      '& .MuiSvgIcon-root': {
        width: 24,
        height: 24,
      },
    },
  },
  calendarResplanRoot: {
    '&.MuiCalendarPicker-root': {
      width: 'auto !important',
      '& .MuiSvgIcon-root': {
        width: 24,
        height: 24,
      },
    },
    '&.MuiMonthPicker-root': {
      width: 'auto !important',
    },
    '& .Mui-selected': {
      backgroundColor: '#dcdff4 !important',
      border: '1px solid #E4E4EF',
      borderRadius: '16px !important',
      color: '#7174AC !important',
    },
    '& .MuiPickersCalendarHeader-root': {
      display: 'none !important',
    },
  },
  filterIntInput: {
    maxWidth: 70,
    '&.MuiInputBase-root, &.MuiInputBase-root.Mui-focused': {
      '& input': {
        textAlign: 'center',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
      },
      '& input[type="number"]': {
        '-moz-appearance': 'textfield',
      },
      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
  },
  booleanLabel: {
    '&.MuiFormControlLabel-root': {
      margin: 0,
    },
  },
  boolClearButton: {
    maxHeight: 22,
  },
  dateDay: {
    '&.MuiButtonBase-root': {
      '&:focus.Mui-selected': {
        backgroundColor: 'rgba(220, 223, 244, 1)',
      },
      '&.Mui-selected': {
        backgroundColor: 'rgba(220, 223, 244, 1)',
      },
    },
  },
  resPlanToggle: {
    '&.MuiButtonBase-root': {
      '&.MuiToggleButton-root': {
        textTransform: 'none',
      },
    },
  },
  groupByFilter: {
    backgroundColor: '#dcdff4 !important',
    border: '0px !important',
    height: '40px',
    borderRadius: '16px !important',
    maxWidth: '100%',
    '& .Mui-selected': {
      backgroundColor: '#ffffff !important',
      border: '1px solid #E4E4EF',
      borderRadius: '16px !important',
    },
    '& .MuiToggleButtonGroup-grouped': {
      background: '#dcdff4',
      color: '#7174AC',
      borderRadius: '16px',
      border: '0px !important',
      padding: '10px 6px',
      fontSize: '14px',
      fontWeight: '600',
    },
  },
}));

export default useStyles;
