import React from 'react';
import { Navigate } from 'react-router';

import baseRoutes from 'Common/shared-ui/src/appInitializer/baseRoutes';

import DashboardLayout from 'Src/components/dashboard/DashboardLayout';
import AuthContainer from '../auth/AuthContainer';
import ProjectsContainer from '../projects/ProjectsContainer';
import ProjectPage from '../projects/ProjectPage';
import ProjectReports from '../projects/ProjectReports';
import ProjectsSettings from '../projectsSettings/ProjectsSettings';
import ProjectCreate from '../projects/ProjectCreate';
import ProjectEdit from '../projects/ProjectEdit';
import ActivityContainer from '../activity/ActivityContainer';
import IssuesContainer from '../issues/IssuesContainer';
import SpentTimeContainer from '../spentTime/SpentTimeContainer';
import GanttContainer from '../gantt/GanttContainer';
import NewsContainer from '../news/NewsContainer';
import ArtContainer from '../art/ArtContainer';
import SpreadsheetContainer from '../spreadsheet/SpreadsheetContainer/SpreadsheetContainer';
import ResourcePlanContainer from '../resourcePlan/ResourcePlanContainer';
import AgileContainer from '../agile/AgileContainer';
import SettingsContainer from '../settings/SettingsContainer';
import FaqContainer from '../faq/FaqContainer';
import ProdCalendarContainer from '../administration/productionCalendar/ProdCalendarContainer';
import LogoutContainer from '../logout/LogoutContainer';
import AdministrationContainer from '../administration/AdministrationContainer';
import SpentTimeCreate from '../spentTime/SpentTimeCreate';
import SpentTimeFormatSavedQuery from '../spentTime/SpentTimeFormatSavedQuery';
import IssuesFormatSavedQuery from '../issues/IssuesFormatSavedQuery';
import ResplanSettingsContainer from '../resplanSettings/ResplanSettingsContainer';
import CalculationTypes from '../administration/calculationTypes/CalculationTypes';
import UsersList from '../administration/users/UsersList';
import UserPage from '../administration/userPage/UserPage';
import IssuePage from '../issuePage/IssuePage';
import RedirectRouter from './Router';
import ContractStagesContainer from '../administration/contractStages/ContractStagesContainer';
import IssueCreateEdit from '../issueCreateEdit/IssueCreateEdit';
import AgileFormatSavedQuery from '../agile/AgileFormatSavedQuery';
import ConfirmationSettings from '../administration/confirmationSettings/ConfirmationSettings';
import ConfirmationPage from '../administration/confirmationPage/ConfirmationPage';
import ConfirmationsPage from '../confirmations/ConfirmationsPage';
import GanttSettings from '../administration/ganttSettings/GanttSettings';
export default [
  ...baseRoutes,
  {
    path: '/login',
    element: <AuthContainer />,
  },
  {
    path: '/logout',
    element: <LogoutContainer />,
  },
  {
    path: '/router',
    element: <RedirectRouter />,
  },
  {
    path: '/',
    exact: true,
    element: <DashboardLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="/projects" />,
      },
      {
        path: '/projects',
        children: [
          {
            index: true,
            element: <ProjectsContainer />,
          },
          {
            path: ':slug',
            children: [
              {
                index: true,
                element: <ProjectPage />,
              },
              {
                path: 'edit',
                element: <ProjectEdit />,
              },
            ],
          },
          {
            path: 'create',
            element: <ProjectCreate />,
          },
        ],
      },
      {
        path: '/projects-settings',
        element: <ProjectsSettings />,
      },
      {
        path: '/activity',
        element: <ActivityContainer />,
      },
      {
        path: '/issues',
        children: [
          {
            index: true,
            element: <IssuesContainer />,
          },
          {
            path: 'query/:id',
            element: <IssuesFormatSavedQuery />,
          },
        ],
      },
      {
        path: '/issues/create',
        element: <IssueCreateEdit></IssueCreateEdit>,
      },
      {
        path: '/projects/:projectId/issues/create',
        element: <IssueCreateEdit></IssueCreateEdit>,
      },
      {
        path: '/issues/:parentId/create',
        element: <IssueCreateEdit></IssueCreateEdit>,
      },
      {
        path: '/projects/:projectId/issues/:parentId/create',
        element: <IssueCreateEdit></IssueCreateEdit>,
      },
      {
        path: '/issues/:id/copy',
        element: <IssueCreateEdit></IssueCreateEdit>,
      },
      {
        path: 'projects/:projectId/issues/:copyId/copy',
        element: <IssueCreateEdit></IssueCreateEdit>,
      },
      {
        path: 'issues/:id/edit',
        element: <IssueCreateEdit></IssueCreateEdit>,
      },
      {
        path: 'projects/:projectId/issues/:id/edit',
        element: <IssueCreateEdit></IssueCreateEdit>,
      },
      {
        path: 'issues/bulk_edit',
        element: <IssueCreateEdit></IssueCreateEdit>,
      },
      {
        path: 'projects/:projectId/issues/:id/bulk_edit',
        element: <IssueCreateEdit></IssueCreateEdit>,
      },
      {
        path: 'issues/:id',
        element: <IssuePage />,
      },
      {
        path: 'projects/:projectId/issues/:id',
        element: <IssuePage />,
      },
      {
        path: '/spentTime',
        children: [
          {
            index: true,
            element: <SpentTimeContainer />,
          },
          {
            path: 'create',
            element: <SpentTimeCreate />,
          },
          {
            path: 'create/bulk',
            element: <SpentTimeCreate />,
          },
          {
            path: 'query/:id',
            element: <SpentTimeFormatSavedQuery />,
          },
        ],
      },
      {
        path: '/gantt',
        element: <GanttContainer />,
      },
      {
        path: '/news',
        element: <NewsContainer />,
      },
      {
        path: '/finances',
        element: <ArtContainer />,
      },
      {
        path: '/spreadsheet',
        element: <SpreadsheetContainer />,
      },
      {
        path: '/resourcePlan',
        element: <ResourcePlanContainer />,
      },
      {
        path: '/agile',
        children: [
          {
            index: true,
            element: <AgileContainer />,
          },
          {
            path: 'query/:id',
            element: <AgileFormatSavedQuery />,
          },
        ],
      },
      {
        path: '/my/account',
        element: <SettingsContainer />,
      },
      {
        path: '/faq',
        element: <FaqContainer />,
      },
      {
        path: '/administration',
        children: [
          {
            index: true,
            element: <AdministrationContainer />,
          },
          {
            path: 'projects',
            element: <>EMPTY PAGE</>,
          },
          {
            path: 'users',
            element: <UsersList />,
          },
          {
            path: 'users/:id',
            element: <UserPage />,
          },
          {
            path: 'groups',
            element: <>EMPTY PAGE</>,
          },
          {
            path: 'permissions',
            element: <>EMPTY PAGE</>,
          },
          {
            path: 'trackers',
            element: <>EMPTY PAGE</>,
          },
          {
            path: 'issue-statuses',
            element: <>EMPTY PAGE</>,
          },
          {
            path: 'workflows',
            element: <>EMPTY PAGE</>,
          },
          {
            path: 'custom-fields',
            element: <>EMPTY PAGE</>,
          },
          {
            path: 'enumerations',
            element: <>EMPTY PAGE</>,
          },
          {
            path: 'project-reports',
            element: <ProjectReports />,
          },
          {
            path: 'production-calendar',
            element: <ProdCalendarContainer />,
          },
          {
            path: 'settings',
            element: <>EMPTY PAGE</>,
          },
          {
            path: 'resourcePlan-settings',
            element: <ResplanSettingsContainer />,
          },
          {
            path: 'calculation-types',
            element: <CalculationTypes />,
          },
          {
            path: 'contract-stages',
            element: <ContractStagesContainer />,
          },
          {
            path: 'confirmation-settings',
            element: <ConfirmationSettings />,
          },
          {
            path: 'gantt-settings',
            element: <GanttSettings />,
          },
        ],
      },
      {
        path: 'confirmation/reinit',
        element: <ConfirmationPage />,
      },
      {
        path: 'confirmations/:id',
        element: <ConfirmationPage />,
      },
      { path: 'confirmations', element: <ConfirmationsPage></ConfirmationsPage> },
      { path: 'confirmations/query/:queryId', element: <ConfirmationsPage></ConfirmationsPage> },
      { path: '*', element: <Navigate to="/errors/error-404" /> },
    ],
  },
];
