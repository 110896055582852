import { useCallback, useRef } from 'react';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

export const filtersDateRange = ['btw', 'thisWeek', 'lastWeek', 'thisMonth', 'lastMonth', 'thisYear'];
const FiltersDateConst = {
  BTW: 'btw',
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  THIS_WEEK: 'thisWeek',
  LAST_WEEK: 'lastWeek',
  THIS_MONTH: 'thisMonth',
  LAST_MONTH: 'lastMonth',
  THIS_YEAR: 'thisYear',
};
dayjs.extend(weekday);
export const filterDateValues = new Map([
  [FiltersDateConst.TODAY, { operator: 'eq', value: dayjs().format('YYYY-MM-DD') }],
  [FiltersDateConst.BTW, { operator: 'btw', value: [dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')] }],
  [FiltersDateConst.YESTERDAY, { operator: 'eq', value: dayjs().subtract(1, 'd').format('YYYY-MM-DD') }],
  [
    FiltersDateConst.THIS_WEEK,
    { operator: 'btw', value: [dayjs().weekday(0).format('YYYY-MM-DD'), dayjs().weekday(6).format('YYYY-MM-DD')] },
  ],
  [
    FiltersDateConst.THIS_MONTH,
    {
      operator: 'btw',
      value: [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().endOf('month').format('YYYY-MM-DD')],
    },
  ],
  [
    FiltersDateConst.LAST_WEEK,
    {
      operator: 'btw',
      value: [
        dayjs().subtract(1, 'w').weekday(0).format('YYYY-MM-DD'),
        dayjs().subtract(1, 'w').weekday(6).format('YYYY-MM-DD'),
      ],
    },
  ],
  [
    FiltersDateConst.LAST_MONTH,
    {
      operator: 'btw',
      value: [
        dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
        dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
      ],
    },
  ],
  [
    FiltersDateConst.THIS_YEAR,
    {
      operator: 'btw',
      value: [dayjs().startOf('year').format('YYYY-MM-DD'), dayjs().endOf('year').format('YYYY-MM-DD')],
    },
  ],
]);

export const renderOptions = type => {
  let data = [];
  const operationTypeText = ['text'];
  const operationTypeSelect = [
    'user',
    'issue',
    'project',
    'tracker',
    'confirmation',
    'confirmation_projects',
    'confirmation_status',
    'confirmation_trackers',
    'confirmation_issues',
    'confirmation_approvers',
    'confirmation_unapprovers',
    'confirmation_authors',
    'confirmation_editors',
  ];
  const operationTypeDate = ['spent_on', 'date'];

  const operationTypeBoolean = ['boolean', 'bool', 'activity'];

  operationTypeText.includes(type) && (data = ['in', 'notin', 'startswith', 'endswith', 'null', 'all']);
  operationTypeSelect.includes(type) && (data = ['eq', 'noteq', 'null', 'all']);
  operationTypeDate.includes(type) &&
    (data = [
      'eq',
      'gte',
      'lte',
      'btw',
      'today',
      'yesterday',
      'thisWeek',
      'lastWeek',
      'thisMonth',
      'lastMonth',
      'thisYear',
      'null',
      'all',
    ]);

  operationTypeBoolean.includes(type) && (data = ['eq', 'noteq']);

  return data;
};

export const filtersToQuery = (params, type = '') => {
  return params.map(item => {
    let value = item.values;
    Array.isArray(item.values) && (value = item.values.map(item => item).join('||'));
    let queryString = `${item.fieldId};${item.operation};${value}`;
    item.operation === 'null' && (queryString = `${item.fieldId};${item.operation}`);
    return queryString;
  });
};
