import React, { useCallback, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Box, Button, Dialog, Grid, TextField, Typography } from '@mui/material';
import ClearIcon from 'Common/shared-ui/src/icons/ClearIcon';
import ChevronIcon from '../../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import { useLocation, useNavigate } from 'react-router';
import ganttIssueRelatedModalStyles from './GanttIssueRelatedModal.styles';

const GanttIssueRelatedModal = ({ intl, open, data, handleSave, handleClose }) => {
  const classes = ganttIssueRelatedModalStyles();
  const [modalData, setModalData] = useState([
    { id: 33233, delay: null },
    { id: 153543, delay: null },
  ]);

  const handleClearButton = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const navigate = useNavigate();
  const handleError = useCallback(
    error => {
      if (error) {
        navigate(`/errors/error-${error.status}`);
      }
    },
    [navigate],
  );

  const handleSaveButton = useCallback(() => {
    handleSave();
  }, [handleSave]);

  const handleTextField = useCallback(
    (event, index) => {
      const updatedObject = { ...modalData[index], delay: event.target.value };
      setModalData([...modalData.slice(0, index), updatedObject, ...modalData.slice(index + 1)]);
    },
    [modalData],
  );

  const handleNumberField = useCallback(
    (event, field) => {
      const reg = new RegExp(/^[0-9-]*$/);
      if (reg.test(event.target.value)) {
        handleTextField(event, field);
      }
    },
    [handleTextField],
  );

  return (
    <Dialog open={open} className={classes.modalRoot} disableRestoreFocus fullWidth>
      <Grid container direction={'column'}>
        {modalData?.map((item, index) => {
          return (
            <Grid item marginTop={index > 0 ? '16px' : 0} key={index}>
              <Grid key={index} container alignItems={'center'}>
                <Typography variant="h5" color={'#41424E'} flex={1}>
                  <Grid container alignItems={'center'}>
                    <Box marginRight={'16px'}>{intl.formatMessage({ id: 'issue_page_follows' })}</Box>
                    <Box marginRight={'24px'}>{`#${item.id}`}</Box>
                    <Box marginRight={'24px'}>{`${intl.formatMessage({
                      id: 'issue_page_related_task_delay',
                    })} (${intl.formatMessage({ id: 'issue_page_related_task_days' })})`}</Box>
                  </Grid>
                </Typography>
                <TextField
                  key={index}
                  sx={{ marginLeft: '15px' }}
                  onChange={event => handleNumberField(event, index)}
                  placeholder={intl.formatMessage({ id: 'enter' })}
                  fullWidth
                  variant="outlined"
                  required
                  value={item.delay ? item.delay : ''}
                  InputLabelProps={{ shrink: false }}
                  className={'formTextField'}
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Grid container justifyContent="center" marginTop={'30px'}>
        <Button disableRipple variant="cutGreyPrimaryBig" onClick={handleClearButton}>
          <Typography variant="h5" fontWeight={700}>
            <FormattedMessage id="cancel" defaultMessage="cancel" />
          </Typography>
        </Button>
        <Box marginLeft={1}>
          <Button disableRipple variant="cutBlueSecondary" onClick={handleSaveButton}>
            <Typography variant="h5" fontWeight={700}>
              <FormattedMessage id="save" defaultMessage="Clear" />
            </Typography>
          </Button>
        </Box>
      </Grid>
    </Dialog>
  );
};

export default injectIntl(GanttIssueRelatedModal);
