import { makeStyles } from '@mui/styles';

const gridFilterModal = makeStyles(theme => ({
  modalRoot: {
    '& .MuiDialog-container': {
      padding: '84px 0 20px 0',
    },
    '& .MuiPaper-root.MuiDialog-paper': {
      overflow: 'visible',
      padding: '20px',
      maxWidth: '600px',
      backgroundColor: '#F1F2F8',
      borderRadius: '16px',
      margin: 0,
      maxHeight: '100%',
    },
    modalPaper: {
      padding: theme.spacing(2.5, 5),
      overflowY: 'auto',
    },
  },
  groupIcon: {
    '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall': {
      right: 10,
    },
  },
  optionsGroupMenu: {
    '&.MuiPopover-root': {
      zIndex: 3000,
    },
    '& .MuiPaper-root': {
      borderRadius: theme.spacing(4),
      '& ul': {
        padding: 0,
      },
    },
  },
  optionsFilterInput: {
    '&.MuiTextField-root': {
      '& .MuiInputBase-root': {
        padding: 0,
        borderRadius: theme.spacing(4, 4, 0, 0),
        backgroundColor: '#ffffff !important',
        borderBottom: '1px solid #E4E4EF',
        '& input': {
          padding: theme.spacing(2, 4),
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 0,
        },
        '&:not(.Mui-error):hover': {
          borderBottom: '1px solid #E4E4EF',
        },
      },
    },
  },
  totalsSelect: {
    padding: theme.spacing(1),
    width: '100%',
    maxWidth: 353,
    '& > div': {
      padding: 0,
      overflowY: 'hidden !important',
      overflowX: 'scroll !important',
      minHeight: '30px !important',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    '& .MuiSelect-icon': {
      display: 'none',
    },
  },
  chosenTotals: {
    padding: theme.spacing(1, 2),
    backgroundColor: '#ffffff',
    border: '1px solid #E4E4EF',
    borderRadius: theme.spacing(4),
    marginRight: theme.spacing(1),
    maxHeight: 30,
    zIndex: 1400,
    ...theme.typography.h5,
    '& .MuiChip-label': {
      padding: 0,
    },
  },
  totalsRemove: {
    '&.MuiSvgIcon-root.MuiChip-deleteIcon': {
      position: 'relative !important',
      zIndex: 3000,
      padding: 0,
      marginLeft: theme.spacing(1),
      marginRight: 0,
    },
  },
  totalsChip: {
    '&.MuiChip-root': {
      height: 'unset',
      backgroundColor: 'transparent',
    },
  },
  totalsItems: {
    '&.MuiPopover-root': {
      zIndex: 2000,
    },
    '& .MuiPaper-root': {
      marginTop: theme.spacing(3),
    },
    '& .MuiList-root': {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
  menuItem: {
    '&.Mui-selected': {
      background: '#DCDFF4 !important',
    },
    '& .MuiTypography-root': {
      textTransform: 'unset',
      color: '#212346',
    },
  },
  multipleAutocomplete: {
    width: '100%',
    '& .MuiInputBase-root': {
      minHeight: '36px',
      height: 'auto',
      '&.MuiOutlinedInput-root': {
        padding: '0',
        paddingRight: '0 !important',
        backgroundColor: '#fff',
        '& .MuiInputBase-input.MuiOutlinedInput-input': {
          color: '#7174AC',
          padding: '8px',
          height: '100%',
        },
        '&:hover': {
          '&.MuiInputBase-root': {
            '&.MuiOutlinedInput-root': {
              backgroundColor: '#fff',
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        },
        '&.Mui-focused': {
          '&.MuiInputBase-root': {
            '&.MuiOutlinedInput-root': {
              backgroundColor: '#fff',
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        },
      },
    },
    '&.Mui-expanded': {
      '& .MuiInputBase-root': {
        '&.MuiOutlinedInput-root': {
          borderRadius: '16px 16px 0 0',
        },
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& + .MuiAutocomplete-popper': {
      //position: 'static!important',
      //transform: 'none!important',
      boxShadow: '0px 5px 10px -8px #A0A1AA66',
      minWidth: 'auto!important',
      //width: '100%!important',
      //maxWidth: '300px!important',
      '& .MuiPaper-root.MuiAutocomplete-paper': {
        borderRadius: '0 0 16px 16px',
        backgroundColor: '#fff',
        border: 'none',
        borderTop: '1px solid #E4E4EF',
        boxShadow: 'none',
        '& .MuiAutocomplete-noOptions': {
          padding: '8px 16px',
          color: '#212346',
          fontSize: '12px',
          lineHeight: '20px',
        },
        '& .MuiAutocomplete-listbox': {
          padding: '0',
          '& li': {
            whiteSpace: 'normal',
            fontSize: '12px',
            lineHeight: '20px',
            color: '#212346',
            padding: '8px 12px',
            '& .MuiButtonBase-root.MuiCheckbox-root': {
              padding: '2px',
              marginRight: '10px',
            },
            '&.Mui-focused': {
              backgroundColor: 'transparent',
            },
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
            '&.Mui-selected': {
              backgroundColor: '#DCDFF4',
            },
          },
          '&::-webkit-scrollbar': {
            width: '2px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#7174AC',
            borderRadius: '5px',
          },
        },
      },
    },
  },
  chip: {
    '&.MuiChip-root': {
      height: '30px',
      margin: '3px 4px',
      fontSize: '12px',
      display: 'flex',
      border: '1px solid #E4E4EF',
      borderRadius: '14px',
      '& .MuiChip-label': {
        padding: '0 8px',
      },
      '& .MuiChip-deleteIcon': {
        position: 'static',
        height: '10px !important',
      },
    },
  },
  rowSelect: {
    '&.MuiInputBase-root': {
      minWidth: '253px',
      marginTop: 0,
      width: '100%',
      fontSize: '14px',
      lineHeight: '20px',
      height: '100%',
      padding: '8px 16px',
      '&::before': {
        borderBottom: 'none',
      },
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '&::before': {
          borderBottom: 'none',
        },
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
      '& .MuiSelect-select': {
        padding: 0,
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      '& .MuiSvgIcon-root.MuiSelect-icon': {
        width: '20px',
        height: '20px',
        position: 'static',
      },
      '& .MuiSelect-nativeInput': {
        padding: '0',
      },
    },
  },
}));

export default gridFilterModal;
