import { makeStyles } from '@mui/styles';
const ganttContainerStyles = makeStyles(theme => ({
  ganttHolder: {
    overflow: 'auto',
    overflowX: 'hidden',
    borderRadius: '16px',
    '&::-webkit-scrollbar': {
      height: '6px',
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#7174AC',
      borderRadius: '5px',
    },
    '& .gantt': {
      //zIndex: 2,
      borderRadius: 0,
      //position: 'static',
      '& ._ganttVerticalContainer_kayy3_1': {
        '& .ganttHeaderSvg': {
          //backgroundColor: '#fff',
          //position: 'absolute',
          //zIndex: 2,
          '& ._calendarHeader_fydlr_31': {
            stroke: 'none',
          },
        },
        '& ._horizontalContainer_kayy3_36': {
          //top: '56px!important',
        },
      },
    },
  },
  cutButton: {
    '&.MuiButtonBase-root': {
      '&.MuiButton-root': {
        backgroundColor: '#6270D0',
        color: '#fff',
        '&.Mui-disabled': {
          backgroundColor: '#DCDFF4',
          color: '#656985',
        },
      },
    },
  },
  resPlanToggle: {
    '&.MuiButtonBase-root': {
      '&.MuiToggleButton-root': {
        textTransform: 'none',
      },
    },
  },
  groupByFilter: {
    backgroundColor: '#dcdff4 !important',
    border: '0px !important',
    height: '40px',
    borderRadius: '16px !important',
    maxWidth: '100%',
    '& .Mui-selected': {
      backgroundColor: '#ffffff !important',
      border: '1px solid #E4E4EF',
      borderRadius: '16px !important',
    },
    '& .MuiToggleButtonGroup-grouped': {
      background: '#dcdff4',
      color: '#7174AC',
      borderRadius: '16px',
      border: '0px !important',
      padding: '10px 6px',
      fontSize: '14px',
      fontWeight: '600',
    },
  },
  taskListCell: {
    display: 'table-cell',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .taskListCell': {
    display: 'table-cell',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  ganttTableWrap: {
    '& .MuiDataGrid-row': {
      margin: 0,
    },
  },
}));

export default ganttContainerStyles;
