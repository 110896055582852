import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  SearchInputRoot: {
    position: 'relative',
    width: 200,
    maxWidth: 200,
    marginRight: theme.spacing(8),
    [theme.breakpoints.maxWidth('sm')]: {
      width: 'unset',
      marginRight: 0,
    },
  },
  inputIcon: {
    position: 'absolute !important',
    left: 4,
    top: 2,
    bottom: 0,
    margin: 'auto 0',
    zIndex: 10,
    '& svg': {
      color: '#7174AC',
    },
    [theme.breakpoints.maxWidth('sm')]: {
      left: 0,
      top: 0,
      margin: 'unset',
      position: 'unset !important',
    },
  },
  searchInput: {
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      padding: '10px 12px 10px 40px',
      lineHeight: '20px',
      height: 'auto',
      background: '#fff',
      '& .MuiAutocomplete-input': {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#212346',
        padding: '0',
        height: 'auto',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&.Mui-expanded': {
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        borderRadius: '16px 16px 0 0',
        '& .MuiOutlinedInput-notchedOutline': {
          padding: 0,
        },
      },
      '& .MuiFormLabel-root.MuiInputLabel-root': {
        display: 'none',
      },
    },
    '&.Mui-focused': {
      '& .MuiFormLabel-root.MuiInputLabel-root': {
        display: 'none',
      },
    },
    '&:hover': {
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        background: '#fff',
      },
    },
    '& + .MuiAutocomplete-popper': {
      minWidth: 'auto!important',
      //width: '100%!important',
      maxWidth: '300px!important',
      '& .MuiPaper-root.MuiAutocomplete-paper': {
        borderRadius: '0 0 16px 16px',
        border: '1px solid #E4E4EF',
        boxShadow: '0px 5px 10px -8px #A0A1AA66',
        '& .MuiAutocomplete-noOptions': {
          padding: '8px 16px',
          color: '#212346',
          fontSize: '12px',
          lineHeight: '20px',
        },
        '& .MuiAutocomplete-loading': {
          padding: '8px 16px',
          color: '#212346',
          fontSize: '12px',
          lineHeight: '20px',
        },
        '& .MuiAutocomplete-listbox': {
          padding: '0',
          '& li': {
            whiteSpace: 'normal',
            fontSize: '12px',
            lineHeight: '20px',
            color: '#212346',
            '&.Mui-focused': {
              backgroundColor: 'transparent',
            },
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
            '&.Mui-selected': {
              backgroundColor: '#DCDFF4',
            },
          },
          '&::-webkit-scrollbar': {
            width: '2px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#7174AC',
            borderRadius: '5px',
          },
        },
      },
    },
  },
}));

export default useStyles;
