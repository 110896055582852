import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Page from '../../../../packages/common/shared-ui/src/components/Page';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import {
  cancelConfirmation,
  confirmationInitial,
  fixConfirmation,
  getConfirmationById,
  getConfirmationReinit,
  getSupersetToken,
} from '../../../../packages/common/api';
import { Link, useParams } from 'react-router-dom';
import userPageStyles from '../userPage/UserPage.styles';

import ConfirmationDocumentsTable from './components/ConfirmationDocumentsTable/ConfirmationDocumentsTable';
import ConfirmationResultTable from './components/ConfirmationDocumentsTable/ConfirmationResultTable/ConfirmationResultTable';
import { useLocation, useNavigate } from 'react-router';
import { generateRedirectUrl } from '../../../../packages/common/utils/getRedirectUrl';
import issuePageStyles from '../../issuePage/IssuePage.styles';

const ConfirmationPage = ({ intl }) => {
  const PageState = { VIEW: 'view', REINIT: 'reinit' };

  const [pageTitle, setPageTitle] = useState('');
  const [loading, setLoading] = useState(true);
  const [generalConfirmation, setGeneralConfirmation] = useState([]);
  const [confirmationResult, setConfirmationResult] = useState({ columns: [], rows: [] });
  const [confirmationDocuments, setConfirmationDocuments] = useState({ columns: [], rows: [] });
  const [confirmationPageState, setConfirmationPageState] = useState(null);
  const [confirmationCancel, setConfirmationCancel] = useState(false);
  const [confirmationFix, setConfirmationFix] = useState(false);
  const pageParams = useParams();
  const commonClasses = userPageStyles();
  const issueCommonStyles = issuePageStyles();
  const location = useLocation();
  const pageState = useRef(
    new Map([
      [PageState.VIEW, { getResponse: getConfirmationById }],
      [PageState.REINIT, { getResponse: getConfirmationReinit }],
    ]),
  );
  const urlParams = useRef(new URLSearchParams(location.search));
  const navigate = useNavigate();
  const handleError = useCallback(
    error => {
      if (error) {
        navigate(`/errors/error-${error.status}`);
      }
    },
    [navigate],
  );

  useEffect(() => {
    pageParams && pageParams.id && setConfirmationPageState(PageState.VIEW);
    location.pathname.includes(PageState.REINIT) && setConfirmationPageState(PageState.REINIT);
  }, [PageState.REINIT, PageState.VIEW, location.pathname, pageParams]);

  useEffect(() => {
    let ignore = false;
    async function getConfirmation() {
      let params;
      confirmationPageState === PageState.VIEW && pageParams && (params = pageParams.id);
      if (confirmationPageState === PageState.REINIT && urlParams.current.get('issues')) {
        params = {};
        params.issues = urlParams.current.get('issues').replaceAll(',', '||');
      }
      const { data: confirmation } = await pageState.current.get(confirmationPageState).getResponse(params);
      if (!ignore) {
        return confirmation;
      }
    }
    if (confirmationPageState) {
      getConfirmation()
        .then(res => {
          res.subject && setPageTitle(res.subject);
          res.standards && setGeneralConfirmation(res.standards);
          if (res.documents) {
            const rowsValue = res.documents.rows.filter(item => item.rowId !== 'total');
            res.documents.fields &&
              res.documents.rows &&
              setConfirmationDocuments({ columns: res.documents.fields, rows: rowsValue });
          }
          if (res.results) {
            const rowsValue = res.results.rows.filter(item => item.rowId !== 'total');
            res.results.fields &&
              res.results.rows &&
              setConfirmationResult({ columns: res.results.fields, rows: rowsValue });
          }
          res.canCancel && setConfirmationCancel(true);
          res.canFix && setConfirmationFix(true);
          setLoading(false);
        })
        .catch(error => {
          handleError(error.response);
        });
      return () => {
        ignore = true;
      };
    }
  }, [PageState.VIEW, PageState.REINIT, confirmationPageState, handleError, pageParams, pageParams.id]);

  const handleCancelConfirmation = useCallback(async () => {
    try {
      const { data: response } = await cancelConfirmation(pageParams.id);
      if (response) {
        setConfirmationCancel(false);
        navigate(`/confirmations/${Number(response)}`);
      }
    } catch (error) {
      console.error('ERROR WITH POST', error);
    }
  }, [navigate, pageParams.id]);

  const handleFixConfirmation = useCallback(async () => {
    try {
      const { data: response } = await fixConfirmation(pageParams.id);
      if (response) {
        setConfirmationFix(false);
        navigate(`/confirmations/${Number(response)}`);
      }
    } catch (error) {
      console.error('ERROR WITH POST', error);
    }
  }, [navigate, pageParams.id]);
  const handleGetRedirectUrl = useCallback((id, columnType) => {
    switch (columnType) {
      case 'createdBy':
      case 'updatedBy':
        return generateRedirectUrl({ host: window.location.origin, path: `/users/${id}` });
      case 'documents':
        return `${window.location.origin}/issues/${id}`;
      case 'project':
        return `${window.location.origin}/projects/${id}`;
      default:
        return '';
    }
  }, []);

  const handleReportLink = useCallback(async link => {
    try {
      const { data: supersetToken } = await getSupersetToken();
      if (supersetToken) {
        window.open(`${link}&token=${supersetToken}`, '_blank');
      }
    } catch (error) {
      console.error('ERROR WITH GET', error);
    }
  }, []);
  return (
    <>
      {loading ? (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="70px">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <Page title={pageTitle} width="100%" sx={{ paddingBottom: '32px' }}>
          <Grid container alignItems={'center'} justifyContent={'space-between'}>
            <Grid item>
              <Typography m={0} padding={'10px 0'} variant="h1">
                {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                {pageTitle}
              </Typography>
            </Grid>
            {confirmationCancel && (
              <Grid item>
                <Button
                  variant="defaultGreyPrimary"
                  disableRipple
                  sx={{ marginLeft: '8px' }}
                  onClick={handleCancelConfirmation}
                >
                  <Typography variant="h4" fontWeight={700}>
                    <FormattedMessage id="cancel" />
                  </Typography>
                </Button>
              </Grid>
            )}
            {confirmationFix && (
              <Grid item>
                <Button
                  variant="defaultBlueSecondary"
                  disableRipple
                  sx={{ marginLeft: '8px' }}
                  onClick={handleFixConfirmation}
                >
                  <Typography variant="h4" fontWeight={700}>
                    <FormattedMessage id="confirmation_fix" />
                  </Typography>
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            direction={'column'}
            className={commonClasses.blockWrapper}
            padding={'16px 32px'}
            marginTop={'20px'}
          >
            <Box>
              {generalConfirmation.length > 0 &&
                generalConfirmation.map((control, index) => (
                  <Grid key={index} container marginTop={'4px'} className={'pageLine'}>
                    <Grid item width={'200px'}>
                      <Typography variant="h4" color={'#41424E'} fontWeight={700}>
                        {intl.formatMessage({
                          id: `confirmation_page_${control.fieldId.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()}`,
                        })}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h4">
                        {Array.isArray(control.valueName) ? (
                          control.valueName.map((item, index) => (
                            <Box key={index} color={'#212346'} marginTop={index > 0 ? '4px' : 0}>
                              {control.fieldId === 'documents' ? (
                                <Link
                                  className={issueCommonStyles.issueLink}
                                  target={'_blank'}
                                  to={handleGetRedirectUrl(control.valueId[index], control.fieldId)}
                                >
                                  {item}
                                </Link>
                              ) : (
                                item
                              )}
                            </Box>
                          ))
                        ) : (
                          <Box color={'#212346'} component={'span'} sx={{ wordBreak: 'break-word' }}>
                            {control.fieldId === 'report' ? (
                              <Box
                                sx={{ cursor: 'pointer' }}
                                color={'#3448FF'}
                                component={'span'}
                                className={issueCommonStyles.issueLink}
                                onClick={() => handleReportLink(control.valueId)}
                              >
                                {control.valueName}
                              </Box>
                            ) : control.fieldId === 'createdBy' ||
                              control.fieldId === 'updatedBy' ||
                              control.fieldId === 'project' ? (
                              <Link
                                className={issueCommonStyles.issueLink}
                                target={'_blank'}
                                to={handleGetRedirectUrl(control.valueId, control.fieldId)}
                              >
                                {control.valueName}
                              </Link>
                            ) : (
                              control.valueName
                            )}
                          </Box>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
            </Box>
          </Grid>
          {confirmationDocuments.columns && confirmationDocuments.rows && (
            <ConfirmationDocumentsTable
              confirmationDocuments={confirmationDocuments}
              pageState={confirmationPageState}
            ></ConfirmationDocumentsTable>
          )}
          {confirmationResult.columns.length > 0 && confirmationResult.rows.length > 0 && (
            <>
              <Typography variant="h3" marginTop={'28px'} fontWeight={600} color={'#082253'}>
                {intl.formatMessage({ id: 'confirmation_result_title' })}
              </Typography>
              <ConfirmationResultTable confirmationResult={confirmationResult}></ConfirmationResultTable>
            </>
          )}
        </Page>
      )}
    </>
  );
};
export default injectIntl(ConfirmationPage);
