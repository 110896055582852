import { makeStyles } from '@mui/styles';

const ganttSettingsStyles = makeStyles(theme => ({
  chip: {
    '&.MuiChip-root': {
      height: '30px',
      margin: '3px 4px',
      fontSize: '12px',
      display: 'flex',
      border: '1px solid #E4E4EF',
      borderRadius: '14px',
      '& .MuiChip-label': {
        padding: '0 8px',
      },
      '& .MuiChip-deleteIcon': {
        position: 'static',
        height: '10px !important',
      },
    },
  },
  selectLabel: {
    '&.MuiFormLabel-root.MuiInputLabel-root': {
      position: 'static',
      width: '200px',
      color: '#41424E',
      lineHeight: '20px',
      transform: 'none',
      display: 'flex',
      flexShrink: 0,
      '& .MuiFormLabel-asterisk': {
        color: '#E03737',
      },
      '&.Mui-focused': {
        transform: 'none',
        left: '10px',
        top: '15px',
      },
    },
  },
  filterSelect: {
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      padding: '8px 65px 8px 16px',
      lineHeight: '20px',
      height: 'auto',
      '& .MuiAutocomplete-endAdornment': {
        '& .MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator': {
          '& .MuiSvgIcon-root': {
            width: '20px',
            height: '20px',
          },
        },
        '& .MuiSvgIcon-root': {
          width: '24px',
          height: '24px',
          position: 'static',
        },
      },
      '& .MuiAutocomplete-input': {
        fontSize: '12px',
        lineHeight: '20px',
        color: '#212346',
        padding: '0',
        height: 'auto',
      },
      '&.Mui-disabled': {
        '& .MuiAutocomplete-input': {
          '&::placeholder': {
            color: '#E4E4EF',
          },
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#E4E4EF',
        },
        '& .MuiAutocomplete-endAdornment': {
          '& .MuiSvgIcon-root path': {
            fill: '#E4E4EF',
          },
        },
      },
    },
    '&.MuiAutocomplete-hasClearIcon': {
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        borderColor: '#3448FF',
        '&:hover': {
          borderColor: '#3448FF',
        },
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      color: '#7174AC',
      fontSize: '14px',
      lineHeight: '20px',
      top: '-5px',
      zIndex: 99,
      '&.MuiFormLabel-filled': {
        display: 'none',
      },
    },
    '&.Mui-expanded': {
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        borderRadius: '16px 16px 0 0',
        '& .MuiOutlinedInput-notchedOutline': {
          padding: 0,
        },
      },
      '& .MuiFormLabel-root.MuiInputLabel-root': {
        display: 'none',
      },
    },
    '&.Mui-focused': {
      '& .MuiFormLabel-root.MuiInputLabel-root': {
        display: 'none',
      },
    },
    '& + .MuiAutocomplete-popper': {
      minWidth: 'auto!important',
      width: '100%!important',
      //maxWidth: '300px!important',
      '& .MuiPaper-root.MuiAutocomplete-paper': {
        borderRadius: '0 0 16px 16px',
        border: '1px solid #E4E4EF',
        borderTop: 'none',
        boxShadow: '0px 5px 10px -8px #A0A1AA66',
        '& .MuiAutocomplete-noOptions': {
          padding: '8px 16px',
          color: '#212346',
          fontSize: '12px',
          lineHeight: '20px',
        },
        '& .MuiAutocomplete-listbox': {
          padding: '0',
          '& li': {
            whiteSpace: 'normal',
            fontSize: '12px',
            lineHeight: '20px',
            color: '#212346',
            '&.Mui-focused': {
              backgroundColor: 'transparent',
            },
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
            '&.Mui-selected': {
              backgroundColor: '#DCDFF4',
            },
          },
          '&::-webkit-scrollbar': {
            width: '2px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#7174AC',
            borderRadius: '5px',
          },
        },
      },
    },
  },
  filterSelectMultiple: {
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      minHeight: '36px',
      padding: '0 65px 0 0',
      '& .MuiAutocomplete-input': {
        marginLeft: '16px',
      },
    },
  },
}));

export default ganttSettingsStyles;
