import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popper,
  TextField,
  Typography,
} from '@mui/material';
import ClearIcon from 'Common/shared-ui/src/icons/ClearIcon';
import ganttEditCellUserStyles from './GanttEditCellUser.styles';
import ChevronIcon from '../../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import issueFormFieldStyles from '../../../issueCreateEdit/components/IssueFormField.styles';
import { useLocation, useNavigate } from 'react-router';
import {
  getGantIssueCreate,
  getGantIssueCreateTrackers,
  getGantIssueEdit,
  getGantParentIssue,
  getGanttIssueUsers,
} from '../../../../../packages/common/api';
import IssueFormField from '../../../issueCreateEdit/components/IssueFormField';
import dayjs from 'dayjs';
import { store } from '../../../app/redux';
import GanttIssuePredecessor from '../GanttIssuePredecessor/GanttIssuePredecessor';
import { FormattedMessage, useIntl, injectIntl } from 'react-intl';
import clsx from 'clsx';
import dataGridTableStyles from '../../../../../packages/common/shared-ui/src/styles/DataGridTable.styles';

const GanttEditCellUser = ({ cellProps, handleField }) => {
  const intl = useIntl();
  const [rowUsers, setRowUsers] = useState([]);
  const dataGridTableStyle = dataGridTableStyles();
  useEffect(() => {
    let params = {
      row: Number(cellProps.row.id),
      group: Number(cellProps.row.project),
      project: Number(cellProps.row.project),
    };
    async function getUsers(params) {
      const { data: users } = await getGanttIssueUsers(params);
      setRowUsers(users);
      return users;
    }
    getUsers(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cellProps, setRowUsers]);
  return (
    <Autocomplete
      noOptionsText={intl.formatMessage({ id: 'not_found' })}
      onChange={(event, newValue) => {
        handleField(newValue, cellProps);
      }}
      className={clsx(dataGridTableStyle.rowAutocomlpete, {
        ['changed']: cellProps.row.changed,
      })}
      disablePortal
      options={rowUsers ? rowUsers : []}
      disableClearable
      renderOption={(props, option) => {
        return (
          <MenuItem
            {...props}
            key={option.valueId + option.valueName}
            value={option.valueId}
            selected={cellProps.row.services.userId === option.valueId}
          >
            {option.valueName}
          </MenuItem>
        );
      }}
      value={cellProps.value ? cellProps.value : ''}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={option => option.valueName || ''}
      PopperComponent={props => <Popper {...props} sx={{ width: '200px!important' }} placement="bottom-start"></Popper>}
      renderInput={params => <TextField {...params} placeholder={intl.formatMessage({ id: 'choose' })} />}
      popupIcon={<ChevronIcon direction={'down'} />}
    />
  );
};

export default injectIntl(GanttEditCellUser);
