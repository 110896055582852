import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  tooltipForm: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: '#F1F2F8',
      borderRadius: '8px',
      padding: '4px',
      color: '#212346',
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 400,
      margin: 0,
      marginBottom: '0!important',
    },
  },
  issueLink: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  createSpentTimeOverlay: {
    borderRadius: theme.spacing(4),
    backgroundColor: '#fff',
    padding: theme.spacing(4),
    maxWidth: 630,
  },
  requiredFieldStar: {
    color: 'rgba(224, 55, 55, 1)',
  },
  optionsGroupMenu: {
    '&.MuiPopover-root': {
      zIndex: 3000,
      maxHeight: 400,
    },
    '& .MuiPaper-root': {
      borderRadius: theme.spacing(4),
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '& ul': {
        padding: 0,
      },
    },
  },
  createSelect: {
    '&.MuiInputBase-root.Mui-disabled': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#E4E4EF',
      },
      opacity: '70%',
    },
  },
  textField: {
    '&.MuiInputBase-root.Mui-disabled': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#E4E4EF',
      },
      opacity: '70%',
    },
  },
}));

export default useStyles;
