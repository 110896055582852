import React, { useCallback } from 'react';
import useStyles from './DragDropLists.styles';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Autocomplete, Box, Grid, Paper, TextField, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { getTranslatedText } from '../../../../utils/getTranslatedText';

const DragDropLists = ({
  firstListOptions,
  secondListOptions,
  getOptionLabel,
  setParentState,
  intl,
  nonDraggableIds = [],
  componentParent = 'default',
}) => {
  const classes = useStyles();

  const handleMoveField = useCallback(
    ({ currentPosition, newPosition, direction }) => {
      // LEFT TO LEFT
      if (direction === 'ltl') {
        setParentState(prevState => {
          const [currentElement] = prevState.availableFields.splice(currentPosition, 1);
          if (newPosition < 0) {
            prevState.availableFields.push(currentElement);
          } else {
            prevState.availableFields.splice(newPosition, 0, currentElement);
          }
          return {
            ...prevState,
            availableFields: prevState.availableFields,
            selectedFields: prevState.selectedFields,
          };
        });
      }
      // RIGHT TO RIGHT
      if (direction === 'rtr') {
        setParentState(prevState => {
          const [currentElement] = prevState.selectedFields.splice(currentPosition, 1);
          if (newPosition < 0) {
            prevState.selectedFields.push(currentElement);
          } else {
            prevState.selectedFields.splice(newPosition, 0, currentElement);
          }
          return {
            ...prevState,
            availableFields: prevState.availableFields,
            selectedFields: prevState.selectedFields,
          };
        });
      }
      if (direction === 'left') {
        setParentState(prevState => {
          const [currentElement] = prevState.selectedFields.splice(currentPosition, 1);
          if (newPosition < 0) {
            prevState.availableFields.push(currentElement);
          } else {
            prevState.availableFields.splice(newPosition, 0, currentElement);
          }
          return {
            ...prevState,
            availableFields: prevState.availableFields,
            selectedFields: prevState.selectedFields,
          };
        });
      }

      if (direction === 'right') {
        setParentState(prevState => {
          const [currentElement] = prevState.availableFields.splice(currentPosition, 1);
          if (newPosition < 0) {
            prevState.selectedFields.push(currentElement);
          } else {
            prevState.selectedFields.splice(newPosition, 0, currentElement);
          }
          return {
            ...prevState,
            availableFields: prevState.availableFields,
            selectedFields: prevState.selectedFields,
          };
        });
      }
    },
    [setParentState],
  );
  const onDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return;
      }
      const { source, destination } = result;
      if (nonDraggableIds.length > 0) {
        if (destination.droppableId === 'selectedFields' && (destination.index === 0 || destination.index === 1)) {
          return;
        }
      }
      if (source.droppableId !== destination.droppableId) {
        if (destination.droppableId === 'selectedFields') {
          handleMoveField({
            currentPosition: source.index,
            newPosition: destination.index,
            direction: 'right',
          });
        } else if (
          destination.droppableId === 'availableFields' &&
          secondListOptions[source.index]?.fieldId !== 'resourcePlan.monthly_distribution'
        ) {
          handleMoveField({
            currentPosition: source.index,
            newPosition: destination.index,
            direction: 'left',
          });
        }
      } else {
        if (destination.droppableId === 'selectedFields') {
          handleMoveField({
            currentPosition: source.index,
            newPosition: destination.index,
            direction: 'rtr',
          });
        } else if (destination.droppableId === 'availableFields') {
          handleMoveField({
            currentPosition: source.index,
            newPosition: destination.index,
            direction: 'ltl',
          });
        }
      }
    },
    [handleMoveField, nonDraggableIds.length, secondListOptions],
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid container marginTop={'20px'} overflow={'hidden'} alignItems={'stretch'} flexWrap={'nowrap'}>
        <Box overflow={'hidden'} marginRight={'10px'} width={'50%'}>
          <Box display={'flex'} flexDirection={'column'} maxHeight={'100%'} overflow={'hidden'}>
            <Typography variant="h5" marginLeft={3}>
              <FormattedMessage id={componentParent === 'agile' ? 'available_fields' : 'spent_time_available_fields'} />
            </Typography>
            <Autocomplete
              open
              multiple
              disablePortal
              disableClearable
              className={classes.customPopper}
              options={firstListOptions.sort((a, b) => {
                const ruCollator = new Intl.Collator('ru-RU');
                const firstTranslatedValue = getTranslatedText(intl, 'filter', a.fieldId, a.fieldName).toLowerCase();
                const secondTranslatedValue = getTranslatedText(intl, 'filter', b.fieldId, b.fieldName).toLowerCase();
                return ruCollator.compare(firstTranslatedValue, secondTranslatedValue);
              })}
              getOptionLabel={getOptionLabel}
              filterOptions={(options, state) => {
                let newOptions = [];
                options.forEach(element => {
                  const firstTranslatedValue = getTranslatedText(
                    intl,
                    'filter',
                    element.fieldId,
                    element.fieldName,
                  ).toLowerCase();
                  if (firstTranslatedValue.replace(',', '').toLowerCase().includes(state.inputValue.toLowerCase())) {
                    newOptions.push(element);
                  }
                });
                return newOptions;
              }}
              renderOption={params => {
                const paramName = params.key.split(';').reverse()[0];
                const paramId = params.key.split(';').reverse()[1];
                let index = firstListOptions.indexOf(firstListOptions.find(item => item.fieldId === paramId));
                const isBlocked = nonDraggableIds.includes(paramId);
                return (
                  <Draggable key={paramId} draggableId={paramId} index={index} isDragDisabled={isBlocked}>
                    {provided => {
                      return (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={classes.fieldsListItem}
                          onClick={() => {
                            if (!isBlocked) {
                              handleMoveField({
                                currentPosition: index,
                                newPosition: -1,
                                direction: 'right',
                              });
                            }
                          }}
                        >
                          <Grid key={params['data-option-index']}>
                            <Typography variant="h5" whiteSpace="nowrap" textOverflow="ellipsis">
                              {paramName}
                            </Typography>
                          </Grid>
                        </Box>
                      );
                    }}
                  </Draggable>
                );
              }}
              renderInput={params => {
                return (
                  <Box key={params.value} onMouseDown={params.inputProps.onMouseDown}>
                    <TextField
                      variant="outlined"
                      {...params}
                      placeholder={intl.formatMessage({ id: 'enter' })}
                      className={classes.optionsFilterInput}
                    />
                  </Box>
                );
              }}
              PaperComponent={params => {
                return (
                  <Droppable droppableId="availableFields">
                    {provided => {
                      return (
                        <>
                          {provided.placeholder}
                          <Paper className={classes.fieldsList}>
                            <Grid container direction="column" ref={provided.innerRef} {...provided.droppableProps}>
                              {params.children}
                            </Grid>
                          </Paper>
                        </>
                      );
                    }}
                  </Droppable>
                );
              }}
              // renderTags={props.renderFirstStepTags}
              noOptionsText={
                <Typography variant="h5">
                  <FormattedMessage id="no_results" />
                </Typography>
              }
            />
          </Box>
        </Box>
        <Box overflow={'hidden'} marginLeft={'10px'} width={'50%'}>
          <Box display={'flex'} flexDirection={'column'} maxHeight={'100%'} overflow={'hidden'}>
            <Typography variant="h5" marginLeft={3}>
              <FormattedMessage id={componentParent === 'agile' ? 'selected_fields' : 'spent_time_chosen_fields'} />
            </Typography>
            <Autocomplete
              multiple
              disableClearable
              className={classes.customPopper}
              disablePortal
              open
              options={secondListOptions}
              getOptionLabel={getOptionLabel}
              filterOptions={(options, state) => {
                let newOptions = [];
                options.forEach(element => {
                  const firstTranslatedValue = getTranslatedText(
                    intl,
                    'filter',
                    element.fieldId,
                    element.fieldName,
                  ).toLowerCase();
                  if (firstTranslatedValue.replace(',', '').toLowerCase().includes(state.inputValue.toLowerCase())) {
                    newOptions.push(element);
                  }
                });
                return newOptions;
              }}
              renderOption={params => {
                const paramName = params.key.split(';').reverse()[0];
                const paramId = params.key.split(';').reverse()[1];
                const isBlocked = nonDraggableIds.includes(paramId);
                let index = secondListOptions.indexOf(secondListOptions.find(item => item.fieldId === paramId));
                return (
                  <Draggable key={params.id} draggableId={params.id} index={index} isDragDisabled={isBlocked}>
                    {provided => {
                      return (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          onClick={() => {
                            if (!isBlocked && !(paramId === 'resourcePlan.monthly_distribution')) {
                              handleMoveField({
                                currentPosition: index,
                                newPosition: 0,
                                direction: 'left',
                              });
                            }
                          }}
                        >
                          <Grid className={isBlocked ? classes.fieldsListDisabledItem : classes.fieldsListItem}>
                            <Typography variant="h5" whiteSpace="nowrap" textOverflow="ellipsis">
                              {paramName}
                            </Typography>
                          </Grid>
                        </Box>
                      );
                    }}
                  </Draggable>
                );
              }}
              renderInput={params => {
                return (
                  <Box key={params.value} onMouseDown={params.inputProps.onMouseDown}>
                    <TextField
                      variant="outlined"
                      {...params}
                      placeholder={intl.formatMessage({ id: 'enter' })}
                      className={classes.optionsFilterInput}
                    />
                  </Box>
                );
              }}
              PaperComponent={params => {
                return (
                  <Droppable droppableId="selectedFields">
                    {provided => (
                      <>
                        {provided.placeholder}
                        <Paper className={classes.fieldsList} ref={provided.innerRef} {...provided.droppableProps}>
                          {params.children}
                        </Paper>
                      </>
                    )}
                  </Droppable>
                );
              }}
              noOptionsText={
                <Typography variant="h5">
                  <FormattedMessage id="no_results" />
                </Typography>
              }
            />
          </Box>
        </Box>
      </Grid>
    </DragDropContext>
  );
};

export default DragDropLists;
