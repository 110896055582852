import React, { useCallback, useState } from 'react';
import SearchIcon from 'Common/shared-ui/src/icons/SearchIcon';
import {
  Autocomplete,
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  Popper,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { getRedirectUrlNoParams } from 'Common/utils/getRedirectUrlNoParams';
import useStyles from './SearchInput.styles';
import { useSelector } from 'react-redux';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import { getGantPredecessorIssues, getProjectsWithQuery, getQueryIssues } from '../../../../packages/common/api';
import { injectIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { useDebouncedFunction } from '../../../../packages/common/utils/debounce';

const SearchInput = ({ intl }) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.maxWidth('sm'));
  const [searchValue, setSearchValue] = React.useState('');
  const [searchOptions, setSearchOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSearching = useCallback(async value => {
    setSearchValue(value);
    try {
      setLoading(true);
      const issues = getQueryIssues({
        searchText: value,
      });
      const projects = getProjectsWithQuery(`filters=project.project;in;${value}`);
      const response = await Promise.all([issues, projects]);
      if (response) {
        setLoading(false);
        setSearchOptions(response[0].data.concat(response[1].rows));
      }
    } catch (error) {
      console.error('ERROR WITH EDIT', error);
    }
  }, []);
  const handleSearchSelect = useCallback(
    value => {
      const issueId = value.rowId ? `/projects/${value.rowId}` : `/issues/${value.valueId}`;
      navigate(issueId);
      setSearchValue('');
      setSearchOptions([]);
    },
    [navigate],
  );
  const debouncedValueLogging = useDebouncedFunction(value => handleSearching(value), 500);
  const handleSearchingDebounce = e => {
    debouncedValueLogging(e);
  };
  return (
    <Box component={'span'} position={'relative'}>
      <SearchIcon sx={{ position: 'absolute', left: '12px', top: '8px', zIndex: 11 }} />
      <Autocomplete
        noOptionsText={intl.formatMessage({ id: 'not_found' })}
        loadingText={intl.formatMessage({ id: 'search_options' })}
        filterOptions={x => x}
        onChange={(event, newValue) => handleSearchSelect(newValue)}
        className={classes.searchInput}
        disablePortal
        options={searchOptions}
        disableClearable
        sx={{ width: '200px' }}
        onBlur={() => setSearchOptions([])}
        //value={searchValue}
        renderOption={(props, option) => {
          return (
            <MenuItem
              {...props}
              key={option.rowId ? `${option.rowId}${option.rowName}` : `${option.valueId}${option.valueName}`}
              value={option.rowId ? option.rowId : option.valueId}
              //selected={option.valueId === item.valueId}
            >
              {option.rowName ? option.rowName : option.valueName}
            </MenuItem>
          );
        }}
        getOptionLabel={option => (option.rowName ? option.rowName : option.valueName ? option.valueName : '')}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        PopperComponent={props => <Popper {...props} placement="bottom-start"></Popper>}
        loading={loading}
        clearOnEscape={true}
        inputValue={searchValue}
        renderInput={params => (
          <TextField
            {...params}
            onChange={ev => handleSearching(ev.target.value)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            //placeholder={intl.formatMessage({ id: 'choose' })}
          />
        )}
        //popupIcon={''}
        forcePopupIcon={false}
      />
    </Box>
    /*<Box className={classes.SearchInputRoot}>
      <IconButton disableRipple className={classes.inputIcon} onClick={goSearchPage}>
        <SearchIcon viewBox="0 0 24 24" width={24} height={24} />
      </IconButton>
      {!isSmallScreen && (
        <TextField
          variant="filled"
          onKeyDown={handleEnter}
          value={searchValue}
          onChange={handleSearch}
          className={classes.searchInput}
        />
      )}
    </Box>*/
  );
};

export default injectIntl(SearchInput);
