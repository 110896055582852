import { makeStyles } from '@mui/styles';

const ganttEditCellUserStyles = makeStyles(theme => ({
  modalRoot: {
    '& .MuiPaper-root.MuiDialog-paper': {
      overflow: 'visible',
      padding: '34px 20px 20px 20px',
      maxWidth: '600px',
      backgroundColor: '#F1F2F8',
      borderRadius: '16px',
      maxHeight: 'calc(100% - 130px)',
      margin: 0,
    },
  },
  modalFieldHolder: {
    height: '100%',
    //overflowX: 'hidden',
    //overflow: 'visible',
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#F1F2F8',
      marginRight: '2px',
    },
    '&::-webkit-scrollbar': {
      width: '4px',
      backgroundColor: 'white !important',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#7174AC',
      borderRadius: '5px',
    },
  },
}));

export default ganttEditCellUserStyles;
